import { type FC, type ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { useLazyQuery } from "@apollo/client";
import { ApolloError } from "@apollo/client";

import { faCircleInfo } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ExecutionDetailsDocument,
  ExecutionDetailsFragment,
  type ExecutionFragment,
  ExecutionMinimalFragment,
  ExecutionStatusEnum,
  type RunFragment,
  RunStatusEnum,
  StepKindEnum,
  useDashboardRunsDetailsQuery,
  useRunChatMutation,
} from "@app_schema";

import { useActionCableSubscription } from "@application/hooks/use_action_cable_subscription";
import { useShowHide } from "@application/hooks/use_show_hide";

import { groupParentID } from "@application/utilities/group_parent_id";

import { Attribute } from "@styled/attribute";
import { Chat } from "@styled/chat";
import { ChatBubble } from "@styled/chat_bubble";
import { FileList } from "@styled/file_list";
import { Headline } from "@styled/headline";
import { HTML } from "@styled/html";
import { Node } from "@styled/node";
import { NodeButtonToggle } from "@styled/node_button_toggle";
import { NodeContent } from "@styled/node_content";
import { NodeGroup } from "@styled/node_group";
import { NodeLink } from "@styled/node_link";
import { NodeList } from "@styled/node_list";
import { NodeMenu } from "@styled/node_menu";
import { NodeName } from "@styled/node_name";
import { NodeSummary } from "@styled/node_summary";
import { Page } from "@styled/page";

import { ExecutionCopyIconButton } from "./execution_copy_icon_button";
import { ExecutionDetailButton } from "./execution_detail_button";
import { ExecutionRetryIconButton } from "./execution_retry_icon_button";
import { ExecutionRetryNodeButton } from "./execution_retry_node_button";
import { ExecutionStatus } from "./execution_status";
import { ExecutionTimer } from "./execution_timer";
import { RunDestroyButton } from "./run_destroy_button";
import { RunName } from "./run_name";
import { RunPauseButton } from "./run_pause_button";
import { RunResumeButton } from "./run_resume_button";
import { RunStatus } from "./run_status";

const DashboardRunChat: FC<{
  run: RunFragment;
  children: ReactNode;
}> = ({ run, children }) => {
  const [execute, { loading }] = useRunChatMutation();

  return (
    <Chat
      loading={loading}
      disabled={run.status !== RunStatusEnum.Paused}
      chat={(input) => execute({ variables: { runID: run.id, input } })}
    >
      {children}
    </Chat>
  );
};

const ExecutionNode: FC<{
  run: RunFragment;
  execution: ExecutionMinimalFragment;
  detailedExecution?: ExecutionDetailsFragment;
  expanded: boolean;
  onToggle(expanded: boolean): void;
  loading: boolean;
  error: ApolloError | undefined;
}> = ({
  run,
  execution,
  detailedExecution,
  expanded,
  onToggle,
  loading,
  error,
}) => (
  <Node>
    <NodeContent>
      <NodeName>
        <ExecutionStatus execution={execution} />
        <div>{execution.step.name}</div>
        <ExecutionTimer execution={execution} />
      </NodeName>
      {expanded && (
        <NodeSummary>
          {loading && <div>Loading execution details...</div>}
          {error && <div>Error loading execution details</div>}
          {detailedExecution && (
            <div className="flex flex-col gap-2">
              {(detailedExecution.prompt || detailedExecution.output) && (
                <div className="flex gap-2">
                  {detailedExecution.prompt && (
                    <ExecutionDetailButton
                      display="prompt"
                      execution={detailedExecution}
                    />
                  )}
                  {detailedExecution.output && (
                    <ExecutionDetailButton
                      display="output"
                      execution={detailedExecution}
                    />
                  )}
                </div>
              )}

              {detailedExecution.html && (
                <Attribute name="Result">
                  <HTML html={detailedExecution.html} />
                </Attribute>
              )}

              {detailedExecution.error && (
                <Attribute name="Error">{detailedExecution.error}</Attribute>
              )}

              {detailedExecution.files.length > 0 && (
                <Attribute name="Files">
                  <FileList attachments={detailedExecution.files} />
                </Attribute>
              )}
            </div>
          )}
        </NodeSummary>
      )}
    </NodeContent>
    <NodeMenu>
      {execution.branchID ? (
        <NodeLink to={`/dashboard/runs/${execution.branchID}`} target="_blank">
          <FontAwesomeIcon icon={faCircleInfo} /> View
        </NodeLink>
      ) : (
        <>
          <ExecutionRetryNodeButton execution={execution} run={run} />
          <NodeButtonToggle onToggle={onToggle} expanded={expanded} />
        </>
      )}
    </NodeMenu>
  </Node>
);

const ExecutionChatBubble: FC<{
  run: RunFragment;
  execution: ExecutionDetailsFragment;
  role: "user" | "system";
}> = ({ execution, run, role }) => {
  const { html, files } = execution;
  if (!html) return null;

  return (
    <ChatBubble
      role={role}
      html={html}
      files={files}
      actions={
        <>
          <ExecutionRetryIconButton execution={execution} run={run} />
          <ExecutionCopyIconButton execution={execution} run={run} />
        </>
      }
    />
  );
};

const ExecutionIteration: FC<{
  iteration: number;
  run: RunFragment;
  groups: Map<string, ExecutionMinimalFragment[]>;
  executions: ExecutionFragment[];
}> = ({ iteration, run, groups, executions }) => {
  const [expanded, setExpanded] = useShowHide(
    executions.some(
      (execution) =>
        execution.status === ExecutionStatusEnum.Paused ||
        execution.status === ExecutionStatusEnum.Failed ||
        execution.status === ExecutionStatusEnum.Executing ||
        execution.step.kind === StepKindEnum.Output ||
        (execution.step.kind === StepKindEnum.Input &&
          execution.status === ExecutionStatusEnum.Succeeded),
    ),
  );

  const root = (
    <Node>
      <NodeContent>
        <NodeName>iteration = {iteration}</NodeName>
      </NodeContent>
      <NodeMenu>
        <NodeButtonToggle onToggle={setExpanded} expanded={expanded} />
      </NodeMenu>
    </Node>
  );

  return (
    <NodeGroup key={iteration} root={root}>
      {expanded && (
        <ExecutionList run={run} executions={executions} groups={groups} />
      )}
    </NodeGroup>
  );
};

const ExecutionItem: FC<{
  run: RunFragment;
  groups: Map<string, ExecutionMinimalFragment[]>;
  execution: ExecutionMinimalFragment;
}> = ({ run, groups, execution }) => {
  const [expanded, setExpanded] = useShowHide(
    execution.status === ExecutionStatusEnum.Paused ||
      execution.status === ExecutionStatusEnum.Failed ||
      execution.status === ExecutionStatusEnum.Executing,
  );
  const children = groups.get(execution.id);

  const [
    loadExecutionDetails,
    { data: executionData, loading: executionLoading, error: executionError },
  ] = useLazyQuery(ExecutionDetailsDocument, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const hasDetailedData =
    executionData?.execution && executionData.execution.result != null;

  useEffect(() => {
    if (
      (expanded ||
        execution.step.kind === StepKindEnum.Output ||
        (execution.step.kind === StepKindEnum.Input &&
          execution.status === ExecutionStatusEnum.Succeeded)) &&
      !hasDetailedData
    ) {
      loadExecutionDetails({ variables: { id: execution.id } });
    }
  }, [
    expanded,
    execution.id,
    execution.step.kind,
    execution.status,
    loadExecutionDetails,
    executionData,
  ]);

  const detailedExecution = executionData?.execution;

  const node = (() => {
    switch (execution.step.kind) {
      case StepKindEnum.Input:
      case StepKindEnum.Output:
        if (!detailedExecution) {
          return (
            <Node>
              <NodeContent>
                <NodeName>
                  <ExecutionStatus execution={execution} />
                  <div>{execution.step.name}</div>
                  <ExecutionTimer execution={execution} />
                </NodeName>
                {(expanded ||
                  execution.step.kind === StepKindEnum.Output ||
                  (execution.step.kind === StepKindEnum.Input &&
                    execution.status === ExecutionStatusEnum.Succeeded)) &&
                  (executionLoading ? (
                    <div>Loading execution details...</div>
                  ) : executionError ? (
                    <div>Error loading execution details</div>
                  ) : null)}
              </NodeContent>
              <NodeMenu>
                {execution.step.kind === StepKindEnum.Input && (
                  <NodeButtonToggle
                    onToggle={setExpanded}
                    expanded={expanded}
                  />
                )}
              </NodeMenu>
            </Node>
          );
        }

        return (
          <ExecutionChatBubble
            run={run}
            execution={detailedExecution}
            role={
              execution.step.kind === StepKindEnum.Input ? "user" : "system"
            }
          />
        );

      default:
        return (
          <ExecutionNode
            run={run}
            execution={execution}
            detailedExecution={detailedExecution}
            expanded={expanded}
            onToggle={setExpanded}
            loading={executionLoading}
            error={executionError}
          />
        );
    }
  })();

  if (!children) return node;
  else {
    const grouped = children.reduce((memo, child) => {
      if (memo.has(child.iteration)) {
        memo.get(child.iteration).push(child);
      } else {
        memo.set(child.iteration, [child]);
      }
      return memo;
    }, new Map());
    const iterations = Array.from(grouped.keys()).sort((a, b) => a - b);

    return (
      <NodeGroup root={node}>
        {expanded &&
          iterations.map((iteration) => (
            <ExecutionIteration
              key={iteration}
              iteration={iteration}
              executions={grouped.get(iteration)}
              run={run}
              groups={groups}
            />
          ))}
      </NodeGroup>
    );
  }
};

const ExecutionList: FC<{
  run: RunFragment;
  executions: ExecutionMinimalFragment[];
  groups: Map<string, ExecutionMinimalFragment[]>;
}> = ({ run, executions, groups }) => (
  <NodeList>
    {executions.map((execution) => (
      <ExecutionItem
        key={execution.id}
        run={run}
        execution={execution}
        groups={groups}
      />
    ))}
  </NodeList>
);

export const DashboardRunsDetails: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [channel] = useState(() => ({ channel: "RunChannel", id: id! }));
  const { data, loading, error, refetch } = useDashboardRunsDetailsQuery({
    variables: { id: id! },
  });

  const subscription = useActionCableSubscription(channel, () => {
    refetch({ id: id! });
  });

  useEffect(() => {
    if (subscription === "connected" && !data) {
      refetch();
    }
  }, [subscription, refetch, data]);

  const retry = () => refetch({ id: id! });

  const run = data?.run;
  if (!run) return null;

  const hide = ({ status, step: { kind } }: ExecutionMinimalFragment) =>
    kind === StepKindEnum.Input && status === ExecutionStatusEnum.Paused;

  const executions = run.executions.filter((execution) => !hide(execution));

  const groups = groupParentID(executions);

  return (
    <DashboardRunChat run={run}>
      <Page loading={loading} error={error} retry={retry}>
        <Headline title={<RunName run={run} />}>
          <RunStatus run={run} />
          <RunPauseButton run={run} />
          <RunResumeButton run={run} />
          <RunDestroyButton
            run={run}
            onDestroy={() => navigate("/dashboard/runs")}
          />
        </Headline>

        <ExecutionList
          run={run}
          groups={groups}
          executions={executions.filter(({ parentID }) => !parentID)}
        />
      </Page>
    </DashboardRunChat>
  );
};
