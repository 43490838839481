import { Navigate } from "react-router-dom";

import {
  type DashboardConnectionsDetailsQuery,
  type DashboardRunsDetailsQuery,
  type DashboardSkillsDetailsQuery,
  type DashboardStepsDetailsQuery,
  type DashboardVaultsDetailsQuery,
  type DashboardWorkflowsDetailsQuery,
} from "@app_schema";

import { type Breadcrumb } from "@application/types/breadcrumb";

import { BLOG_ARTICLE_DETAILS_LOADER } from "@application/loaders/blog_article_details_loader";
import { BLOG_AUTHOR_DETAILS_LOADER } from "@application/loaders/blog_author_details_loader";
import { BLOG_LIST_LOADER } from "@application/loaders/blog_list_loader";
import { DASHBOARD_BILLING_LOADER } from "@application/loaders/dashboard_billing_loader";
import { DASHBOARD_CONNECTIONS_DETAILS_LOADER } from "@application/loaders/dashboard_connections_details_loader";
import { DASHBOARD_CONNECTIONS_LIST_LOADER } from "@application/loaders/dashboard_connections_list_loader";
import { DASHBOARD_MAILBOXES_DETAILS_LOADER } from "@application/loaders/dashboard_mailboxes_details_loader";
import { DASHBOARD_MAILBOXES_LIST_LOADER } from "@application/loaders/dashboard_mailboxes_list_loader";
import { DASHBOARD_RUNS_DETAILS_LOADER } from "@application/loaders/dashboard_runs_details_loader";
import { DASHBOARD_RUNS_LIST_LOADER } from "@application/loaders/dashboard_runs_list_loader";
import { DASHBOARD_SCHEDULES_LIST_LOADER } from "@application/loaders/dashboard_schedules_list_loader";
import { DASHBOARD_SETTINGS_LOADER } from "@application/loaders/dashboard_settings_loader";
import { DASHBOARD_SKILLS_DETAILS_LOADER } from "@application/loaders/dashboard_skills_details_loader";
import { DASHBOARD_SKILLS_LIST_LOADER } from "@application/loaders/dashboard_skills_list_loader";
import { DASHBOARD_STEPS_DETAILS_LOADER } from "@application/loaders/dashboard_steps_details_loader";
import { DASHBOARD_USERS_LIST_LOADER } from "@application/loaders/dashboard_users_list_loader";
import { DASHBOARD_VAULTS_DETAILS_LOADER } from "@application/loaders/dashboard_vaults_detail_loader";
import { DASHBOARD_VAULTS_LIST_LOADER } from "@application/loaders/dashboard_vaults_list_loader";
import { DASHBOARD_WEBHOOKS_LIST_LOADER } from "@application/loaders/dashboard_webhooks_list_loader";
import { DASHBOARD_WORKFLOWS_DETAILS_LOADER } from "@application/loaders/dashboard_workflows_details_loader";
import { DASHBOARD_WORKFLOWS_LIST_LOADER } from "@application/loaders/dashboard_workflows_list_loader";
import { LOGOUT_LOADER } from "@application/loaders/logout_loader";
import { ME_LOADER } from "@application/loaders/me_loader";
import { TOKEN_LOADER } from "@application/loaders/token_loader";

import { Activate } from "./activate";
import { BlogArticleDetails } from "./blog/blog_article_details";
import { BlogAuthorDetails } from "./blog/blog_author_details";
import { BlogLayout } from "./blog/blog_layout";
import { BlogList } from "./blog/blog_list";
import { Dashboard } from "./dashboard";
import { DashboardConnectionDetails } from "./dashboard/connections_details";
import { DashboardConnectionsEdit } from "./dashboard/connections_edit";
import { DashboardConnectionsList } from "./dashboard/connections_list";
import { DashboardMailboxesDetails } from "./dashboard/mailboxes_details";
import { DashboardMailboxesForm } from "./dashboard/mailboxes_form";
import { DashboardMailboxesList } from "./dashboard/mailboxes_list";
import { DashboardRunsDetails } from "./dashboard/run_details";
import { DashboardRunsList } from "./dashboard/runs_list";
import { DashboardSchedulesForm } from "./dashboard/schedules_form";
import { DashboardSchedulesList } from "./dashboard/schedules_list";
import { DashboardSettings } from "./dashboard/settings";
import { DashboardSkillsDetails } from "./dashboard/skills_details";
import { DashboardSkillsEdit } from "./dashboard/skills_edit";
import { DashboardSkillsList } from "./dashboard/skills_list";
import { DashboardSkillsNew } from "./dashboard/skills_new";
import { DashboardStepsDetails } from "./dashboard/steps_details";
import { DashboardStepsEdit } from "./dashboard/steps_edit";
import { DashboardStepsNew } from "./dashboard/steps_new";
import { DashboardUsersForm } from "./dashboard/users_form";
import { DashboardUsersList } from "./dashboard/users_list";
import { DashboardVaultsDetails } from "./dashboard/vaults_details";
import { DashboardVaultsEdit } from "./dashboard/vaults_edit";
import { DashboardVaultsList } from "./dashboard/vaults_list";
import { DashboardVaultsNew } from "./dashboard/vaults_new";
import { DashboardWebhooksDetails } from "./dashboard/webhooks_details";
import { DashboardWebhooksForm } from "./dashboard/webhooks_form";
import { DashboardWebhooksList } from "./dashboard/webhooks_list";
import { DashboardWorkflowsDetails } from "./dashboard/workflows_details";
import { DashboardWorkflowsEdit } from "./dashboard/workflows_edit";
import { DashboardWorkflowsList } from "./dashboard/workflows_list";
import { DashboardWorkflowsNew } from "./dashboard/workflows_new";
import { Home } from "./home";
import { Login } from "./login";
import { DASHBOARD_BILLING_ROUTE } from "./routes/dashboard_billing_route";
import { DASHBOARD_CONNECTIONS_ROUTE } from "./routes/dashboard_conections_route";
import { DASHBOARD_MAILBOXES_ROUTE } from "./routes/dashboard_mailboxes_route";
import { DASHBOARD_ROUTE } from "./routes/dashboard_route";
import { DASHBOARD_RUNS_ROUTE } from "./routes/dashboard_runs_route";
import { DASHBOARD_SCHEDULES_ROUTE } from "./routes/dashboard_schedules_route";
import { DASHBOARD_SETTINGS_ROUTE } from "./routes/dashboard_settings_route";
import { DASHBOARD_SKILLS_ROUTE } from "./routes/dashboard_skills_route";
import { DASHBOARD_TRIGGERS_ROUTE } from "./routes/dashboard_triggers_route";
import { DASHBOARD_USERS_ROUTE } from "./routes/dashboard_users_route";
import { DASHBOARD_VAULTS_ROUTE } from "./routes/dashboard_vaults_route";
import { DASHBOARD_WEBHOOKS_ROUTE } from "./routes/dashboard_webhooks_route";
import { DASHBOARD_WORKFLOWS_ROUTE } from "./routes/dashboard_workflows_route";
import { Signup } from "./signup";
import { Verify } from "./verify";

export const ROUTES = [
  {
    path: "/",
    element: <Home />,
    index: true,
  },
  {
    path: "auth/:token",
    loader: TOKEN_LOADER,
  },
  {
    path: "activate/:sid",
    element: <Activate />,
  },
  {
    path: "verify/:sid",
    element: <Verify />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "signup",
    element: <Signup />,
  },
  {
    path: "logout",
    loader: LOGOUT_LOADER,
  },
  {
    path: "blog",
    element: <BlogLayout />,
    children: [
      {
        index: true,
        element: <BlogList />,
        loader: BLOG_LIST_LOADER,
      },
      {
        path: "authors/:slug",
        element: <BlogAuthorDetails />,
        loader: BLOG_AUTHOR_DETAILS_LOADER,
      },
      {
        path: "articles/:slug",
        element: <BlogArticleDetails />,
        loader: BLOG_ARTICLE_DETAILS_LOADER,
      },
      {
        path: "*",
        element: <Navigate to="/blog" replace />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <Dashboard />,
    loader: ME_LOADER,
    handle: DASHBOARD_ROUTE,
    children: [
      {
        path: "workflows",
        handle: DASHBOARD_WORKFLOWS_ROUTE,
        children: [
          {
            index: true,
            element: <DashboardWorkflowsList />,
            loader: DASHBOARD_WORKFLOWS_LIST_LOADER,
          },
          {
            path: "new",
            element: <DashboardWorkflowsNew />,
            handle: {
              name: "New",
              to: "/dashboard/workflows/new",
            },
          },
          {
            path: ":workflowID/edit",
            element: <DashboardWorkflowsEdit />,
            loader: DASHBOARD_WORKFLOWS_DETAILS_LOADER,
            handle: (data: DashboardWorkflowsDetailsQuery): Breadcrumb => ({
              name: data.workflow.name,
              to: `/dashboard/workflows/${data.workflow.id}/edit`,
            }),
          },
          {
            path: ":workflowID",
            loader: DASHBOARD_WORKFLOWS_DETAILS_LOADER,
            handle: (data: DashboardWorkflowsDetailsQuery): Breadcrumb => ({
              name: data.workflow.name,
              to: `/dashboard/workflows/${data.workflow.id}`,
            }),
            children: [
              {
                index: true,
                element: <DashboardWorkflowsDetails />,
              },
              {
                path: "steps",
                children: [
                  {
                    path: ":stepID",
                    element: <DashboardStepsDetails />,
                    loader: DASHBOARD_STEPS_DETAILS_LOADER,
                    handle: (data: DashboardStepsDetailsQuery): Breadcrumb => ({
                      name: data.workflow.step.name,
                      to: `/dashboard/workflows/${data.workflow.id}/steps/${data.workflow.step.id}`,
                    }),
                  },
                  {
                    path: "new",
                    element: <DashboardStepsNew />,
                  },
                  {
                    path: ":stepID/edit",
                    element: <DashboardStepsEdit />,
                    loader: DASHBOARD_STEPS_DETAILS_LOADER,
                    handle: (data: DashboardStepsDetailsQuery): Breadcrumb => ({
                      name: data.workflow.step.name,
                      to: `/dashboard/workflows/${data.workflow.id}/steps/${data.workflow.step.id}/edit`,
                    }),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "runs",
        handle: DASHBOARD_RUNS_ROUTE,
        children: [
          {
            index: true,
            element: <DashboardRunsList />,
            loader: DASHBOARD_RUNS_LIST_LOADER,
          },
          {
            path: ":id",
            element: <DashboardRunsDetails />,
            loader: DASHBOARD_RUNS_DETAILS_LOADER,
            handle: (data: DashboardRunsDetailsQuery): Breadcrumb => ({
              name: `#${data.run.id} - ${data.run.workflow.name}`,
              to: `/dashboard/runs/${data.run.id}`,
            }),
          },
        ],
      },
      {
        path: "skills",
        handle: DASHBOARD_SKILLS_ROUTE,
        children: [
          {
            index: true,
            element: <DashboardSkillsList />,
            loader: DASHBOARD_SKILLS_LIST_LOADER,
          },
          {
            path: ":id",
            element: <DashboardSkillsDetails />,
            loader: DASHBOARD_SKILLS_DETAILS_LOADER,
            handle: (data: DashboardSkillsDetailsQuery): Breadcrumb => ({
              name: data.skill.name,
              to: `/dashboard/skills/${data.skill.id}`,
            }),
          },
          {
            path: ":id/edit",
            element: <DashboardSkillsEdit />,
            loader: DASHBOARD_SKILLS_DETAILS_LOADER,
            handle: (data: DashboardSkillsDetailsQuery): Breadcrumb => ({
              name: data.skill.name,
              to: `/dashboard/skills/${data.skill.id}/edit`,
            }),
          },
          {
            path: "new",
            element: <DashboardSkillsNew />,
            handle: {
              name: "New",
              to: "/dashboard/skills/new",
            },
          },
        ],
      },
      {
        path: "users",
        handle: DASHBOARD_USERS_ROUTE,
        children: [
          {
            index: true,
            element: <DashboardUsersList />,
            loader: DASHBOARD_USERS_LIST_LOADER,
          },
          {
            path: "new",
            element: <DashboardUsersForm />,
            handle: {
              name: "New",
              to: "/dashboard/users/new",
            },
          },
        ],
      },
      {
        path: "triggers",
        handle: DASHBOARD_TRIGGERS_ROUTE,
        children: [
          {
            path: "schedules",
            handle: DASHBOARD_SCHEDULES_ROUTE,
            children: [
              {
                index: true,
                element: <DashboardSchedulesList />,
                loader: DASHBOARD_SCHEDULES_LIST_LOADER,
              },
              {
                path: "new",
                element: <DashboardSchedulesForm />,
                handle: {
                  name: "New",
                  to: "/dashboard/triggers/schedules/new",
                },
              },
            ],
          },
          {
            path: "mailboxes",
            handle: DASHBOARD_MAILBOXES_ROUTE,
            children: [
              {
                index: true,
                element: <DashboardMailboxesList />,
                loader: DASHBOARD_MAILBOXES_LIST_LOADER,
              },
              {
                path: "new",
                element: <DashboardMailboxesForm />,
                handle: {
                  name: "New",
                  to: "/dashboard/triggers/mailboxes/new",
                },
              },
              {
                path: ":id",
                element: <DashboardMailboxesDetails />,
                handle: { name: "Mailbox" },
                loader: DASHBOARD_MAILBOXES_DETAILS_LOADER,
              },
            ],
          },
          {
            path: "webhooks",
            handle: DASHBOARD_WEBHOOKS_ROUTE,
            children: [
              {
                index: true,
                element: <DashboardWebhooksList />,
                loader: DASHBOARD_WEBHOOKS_LIST_LOADER,
              },
              {
                path: "new",
                element: <DashboardWebhooksForm />,
                handle: {
                  name: "New",
                  to: "/dashboard/triggers/webhooks/new",
                },
              },
              {
                path: ":id",
                element: <DashboardWebhooksDetails />,
                handle: { name: "Webhook" },
              },
            ],
          },
          {
            index: true,
            element: <Navigate to="/dashboard/triggers/webhooks" replace />,
          },
        ],
      },
      {
        path: "billing",
        loader: DASHBOARD_BILLING_LOADER,
        handle: DASHBOARD_BILLING_ROUTE,
      },
      {
        path: "connections",
        handle: DASHBOARD_CONNECTIONS_ROUTE,
        children: [
          {
            index: true,
            element: <DashboardConnectionsList />,
            loader: DASHBOARD_CONNECTIONS_LIST_LOADER,
          },
          {
            path: ":id",
            element: <DashboardConnectionDetails />,
            loader: DASHBOARD_CONNECTIONS_DETAILS_LOADER,
            handle: (data: DashboardConnectionsDetailsQuery): Breadcrumb => ({
              name: data.connection.name,
              to: `/dashboard/connections/${data.connection.id}`,
            }),
          },
          {
            path: ":id/edit",
            element: <DashboardConnectionsEdit />,
            loader: DASHBOARD_CONNECTIONS_DETAILS_LOADER,
            handle: (data: DashboardConnectionsDetailsQuery): Breadcrumb => ({
              name: data.connection.name,
              to: `/dashboard/connections/${data.connection.id}/edit`,
            }),
          },
        ],
      },
      {
        path: "vaults",
        handle: DASHBOARD_VAULTS_ROUTE,
        children: [
          {
            index: true,
            element: <DashboardVaultsList />,
            loader: DASHBOARD_VAULTS_LIST_LOADER,
          },
          {
            path: "new",
            element: <DashboardVaultsNew />,
            handle: {
              name: "New",
              to: "/dashboard/vaults/new",
            },
          },
          {
            path: ":id",
            loader: DASHBOARD_VAULTS_DETAILS_LOADER,
            element: <DashboardVaultsDetails />,
            handle: (data: DashboardVaultsDetailsQuery): Breadcrumb => ({
              name: data.vault.name,
              to: `/dashboard/vaults/${data.vault.id}`,
            }),
          },
          {
            path: ":id/edit",
            element: <DashboardVaultsEdit />,
            loader: DASHBOARD_VAULTS_DETAILS_LOADER,
            handle: (data: DashboardVaultsDetailsQuery): Breadcrumb => ({
              name: `${data.vault.name}`,
              to: `/dashboard/vaults/${data.vault.id}`,
            }),
          },
        ],
      },
      {
        path: "settings",
        element: <DashboardSettings />,
        loader: DASHBOARD_SETTINGS_LOADER,
        handle: DASHBOARD_SETTINGS_ROUTE,
      },
      {
        index: true,
        element: <Navigate to="/dashboard/workflows" replace />,
      },
    ],
  },
];
